import auth from '@/middleware/auth.js'

export default [
    {
        path: '',
        component: () => import('@/layouts/Constelacion'),
        children: [
            {
                path: '/condusef',
                name: 'condusef',
                component: () => import('@/apps/condusef/pages/Dashboard'),
                meta: {
                    title: 'Condusef',
                    middleware: auth
                }
            },

            {
                path: '/condusef/quejas',
                name: 'condusef-quejas',
                component: () => import('@/apps/condusef/pages/Quejas'),
                meta: {
                    title: 'Quejas - Condusef',
                    middleware: auth
                }
            },

            {
                path: '/condusef/admin/configuracion',
                name: 'condusef-admin-configuracion',
                component: () => import('@/apps/condusef/pages/Admin/Configuracion'),
                meta: {
                    title: 'Administrador - Configuración - Condusef',
                    middleware: auth
                }
            },

            {
                path: '/condusef/admin/catalogos',
                name: 'condusef-admin-catalogos',
                component: () => import('@/apps/condusef/pages/Admin/Catalogo'),
                meta: {
                    title: 'Administrador - Catalogos - Condusef',
                    middleware: auth
                }
            },


            {
                path: '/condusef/consultas-reune',
                name: 'condusef-consultas-reune',
                component: () => import('@/apps/condusef/pages/Consultas'),
                meta: {
                    title: 'Consultas - REUNE',
                    middleware: auth
                }
            },
            {
                path: '/condusef/reclamaciones-reune',
                name: 'condusef-reclamaciones-reune',
                component: () => import('@/apps/condusef/pages/Reclamaciones.vue'),
                meta: {
                    title: 'Reclamaciones - REUNE',
                    middleware: auth
                }
            },
            {
                path: '/condusef/aclaraciones-reune',
                name: 'condusef-aclaraciones-reune',
                component: () => import('@/apps/condusef/pages/Aclaraciones.vue'),
                meta: {
                    title: 'Aclaraciones - REUNE',
                    middleware: auth
                }
            },
        ]

    }
]